import {projectCode} from "../components/url";

export const setLoginDetails = (p) => {
    return {
      type: `SET_${projectCode}_LOGIN_DETAILS`,
      payload: p,
    };
  };

  export const setDashboardDetails = (p) => {
    return {
      type: `SET_${projectCode}_LOGIN_DETAILS`,
      payload: p,
    };
  };

  export const setGeneralDetails = (p) => {
    return {
      type: `SET_${projectCode}_GENERAL_DETAILS`,
      payload: p,
    };
  };

  export const setAdmissionDetails = (p) => {
    return {
      type: `SET_${projectCode}_ADMISSION_DETAILS`,
      payload: p,
    };
  };

export const setPermissionDetails = (p) => {
  return {
    type: `SET_${projectCode}_PERMISSION_DETAILS`,
    payload: p,
  };
};

  