import React, { useState} from "react";
import {currencyConverter, generate_token, paymentAPIKey, serverLink} from "../url";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../common/loader";
import {toast} from "react-toastify";
import {usePaystackPayment} from "react-paystack";
import { setLoginDetails} from "../../action/actions";

function FundWallet(props) {
    const { patientSerial, patientName, EmailAddress, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)

    const walletInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        EmailAddress: props.EmailAddress,
        amount: 0,
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.patientSerial}`,
    }
    const [formData, setFormData] = useState(walletInitial)

    const config = {
        reference: "TRANX"+generate_token(10),
        email: formData.EmailAddress,
        amount: formData.amount  * 100,
        publicKey: paymentAPIKey,
        currency: 'NGN',
    };

    const resetFormData = () => {
        setFormData(walletInitial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const onPostPayment =  (ref) => {
       try {
           setIsFormLoading(true)
           const sendData = {
               ...formData,
               patientSerial: formData.patientSerial,
               paymentReference: ref.trxref,
               paymentAmount: formData.amount,
               paymentStatus: "Paid",
               paymentFor: "Wallet TopUp",
               paymentType: "Credit",
               amountDue: formData.amount,
               amountPaid: formData.amount,
               itemName: "Fund Wallet",
               paymentMethod: "Paystack",
           }

           toast.info("please wait...");
            axios.post(`${serverLink}payment/post-payment`, sendData, token).then(async (res) => {
               if (res.data.message === "success") {
                   await axios.get(`${serverLink}patient/wallet/${patientSerial}`)
                       .then((result) => {
                           if (result.data.length > 0){
                               props.setOnLoginDetails([{...props.loginData[0], walletAmount: result.data[0].walletAmount}])
                               toast.success("Wallet Credited Successfully");
                               setIsFormLoading(false);
                               props.setRebuildPayment(generate_token(5));
                               document.getElementById("closeFund").click();
                               resetFormData();
                           }else {
                               setIsFormLoading(false);
                               toast.error("Something went wrong updating wallet. Please try again!");
                           }
                           setIsLoading(false)
                       })
                       .catch((err) => {
                           setIsLoading(false);
                           showAlert(
                               "NETWORK ERROR",
                               "Please check your connection and try again!",
                               "error"
                           );
                       });

               } else {
                   setIsFormLoading(false);
                   toast.error("Something went wrong posting payment. Please try again!");
               }
           }).catch((error) => {
               showAlert(
                   "NETWORK ERROR",
                   "Please check your connection and try again!",
                   "error"
               );
           });
       }catch (e) {
           showAlert(
               "PAYMENT ERROR",
               "Ooops!, something went wrong posting payment. please try again!",
               "error"
           );
       }
    }

    const onSuccess = (reference) => {
        onPostPayment(reference)
    };

    const onClose = () => {
        console.log('closed')
    }

    const initializePayment = usePaystackPayment(config);

    const paymentInitialize =  () => {
        if (formData.amount < 1) {
            toast.error("Please enter amount to fund wallet");
            return false;
        }
        initializePayment(onSuccess, onClose)
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`} id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×
                        </button>
                    </div>
                    <form>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                    <br/>
                                    <p className="text-muted">{patientName}</p>
                                </div>
                                <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                    <br/>
                                    <p className="text-muted">{patientSerial}</p>
                                </div>
                                <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                    <br/>
                                    <p className="text-muted">{currencyConverter(walletAmount)}</p>
                                </div>
                                <div className="col-md-12"
                                     style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                                <div className="mb-3 form-group col-md-12">
                                    <label className="form-label">Amount</label>
                                    <input
                                        type="number"
                                        id="amount"
                                        name="amount"
                                        className="form-control"
                                        value={formData.amount}
                                        min={0}
                                        onChange={onEdit}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                IsFormLoading ?
                                    <button type="button" className="btn btn-primary ms-auto">
                                        <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                    </button>
                                    :
                                    <div className="col-12">
                                        <button type="button" id="pay"
                                                className={`btn btn-info px-5 w-100`}
                                                style={{height: '60px'}} onClick={() => {
                                            paymentInitialize();
                                        }}>
                                            Pay Now
                                            <div
                                                className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                <i className="fa fa-arrow-right"/>
                                            </div>
                                        </button>
                                    </div>
                                //     <button type="button" onClick={addAllergy}
                                // className="btn btn-info waves-effect waves-light">{props.btn ?? "Fund Wallet"}</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FundWallet);