import React, {useEffect, useState} from "react";
import {generate_token, getDayName, serverLink} from "../url";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Select from "react-select";
import Loader from "../common/loader";
import {toast} from "react-toastify";
import MakePayment from "../finance/choose-payment/choose-payment";
function BookAppointment(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsGettingSchedules, setIsGettingSchedules] = useState(false)
    const [serviceTypeList, setServiceTypeList] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [doctorsList, setDoctorsList] = useState([])
    const [specialisationList, setSpecialisationList] = useState([])
    const [doctor, setDoctor] = useState([])
    const [serviceType, setServiceType] = useState([])
    const [services, setServices] = useState([])
    const [specialization, setSpecialization] = useState([])
    const [scheduleSlots, setScheduleSlots] = useState([])

    const appointmentInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        doctorID: "",
        doctorID2: "",
        serviceID: "",
        serviceID2: "",
        consultationTypeID: "",
        consultationTypeID2: "",
        itemName: "",
        appointmentDepartment: "",
        appointmentDepartment2: "",
        appointmentDetail: "",
        appointmentDate: "",
        appointmentTime: "",
        slotID: "",
        paymentOption: "",
        appointmentAmount: 0,
        walletAmount: props.walletAmount,
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.patientSerial}`,
    }
    const [formData, setFormData] = useState(appointmentInitial)
    const resetFormData = () => {
        setFormData(appointmentInitial)
    }

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}patient-portal/appointment/data`)
            .then((result) => {
                let specialisation = []; let doctors = []; let service_types = []; let service_list = [];
                result.data.specialisations.map((sp) => {
                    specialisation.push({value: sp.specialisationID, label: sp.specialisationName})
                })

                result.data.doctors.map((row) => {
                    doctors.push({value: row.userID, label: row.doctorName +' => ('+ row.userID+')', phone_number: row.phoneNumber, email_address: row.emailAddress, specialisation: row.specialisationID})
                });

                result.data.serviceTypes.map((row) => {
                    service_types.push({value: row.serviceID, label: row.serviceName, service_description: row.serviceDescription})
                });

                result.data.services.map((row) => {
                    service_list.push({value: row.serviceDataID, label: row.serviceItemName, service_id: row.serviceID, amount: row.serviceAmount, description: row.serviceDescription})
                });

                setDoctorsList(doctors);
                setSpecialisationList(specialisation);
                setSpecialization(specialisation);
                setServiceTypeList(service_types);
                setServiceType(service_types);
                setServiceList(service_list);

                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const onEdit = async (e) => {
        if (e.target.id === 'appointmentDate'){
            setIsGettingSchedules(true)
            const val = e.target.value;
            setFormData({...formData, appointmentDate: val});
            let date_day = getDayName(new Date(val))
            if (formData.doctorID !== ''){
                const schedules  = await axios.get(`${serverLink}patient-portal/appointment/list/${formData.doctorID}/${date_day}`, token)
                if (schedules.data.message === 'success'){
                    let doctor_schedule = schedules.data.data;
                    let doctor_slots = schedules.data.slots;
                    if (doctor_schedule.length > 0){
                        const schedule_day_id = doctor_schedule[0].daysID;
                        const schedule_slots = doctor_slots.filter(e=>e.daysID === schedule_day_id );
                        const sorted_slots = schedule_slots.sort((a, b) => {
                            const statusA = a.slot.toLowerCase();
                            const statusB = b.slot.toLowerCase();

                            if (statusA < statusB) return -1;
                            if (statusA > statusB) return 1;
                            return 0;
                        })
                        setScheduleSlots(sorted_slots)
                    }else{
                        setScheduleSlots([])
                        setFormData({...formData, appointmentTime: '', slotID: ''})
                        toast.error('The selected doctor have no schedule for the selected date')
                    }
                    setIsGettingSchedules(false)
                }
            }else{
                setIsGettingSchedules(false)
                setScheduleSlots([])
                toast.error('Please select a doctor first')
            }

        }

        if (e.target.name === 'appointmentTime'){
            let slot_time = e.target.getAttribute('data');
            let slot_id = e.target.getAttribute('data_id');
            setFormData({...formData, appointmentTime: slot_time, slotID: slot_id})
        }
    }

    const onChangeService = (e) => {
        const val = e.value;
        setFormData({
            ...formData,
            serviceID: val,
            serviceID2: e,
            itemName: e.label,
            appointmentAmount: e.amount,
        })
    }
    const onChangeServiceType = (e) => {
        const val = e.value;
        setServices(serviceList.filter(e=>e.service_id === val))
        setFormData({
            ...formData,
            consultationTypeID: val,
            consultationTypeID2: e,
            serviceID: '',
            serviceID2: '',
        })
    }

    const onChangeSpecialisation = (e) => {
        const val = e.value;
        setDoctor(doctorsList.filter(e=>e.specialisation === val))
        setFormData({
            ...formData,
            appointmentDepartment: val,
            appointmentDepartment2: e,
            doctorID: '',
            doctorID2: '',
        })
    }

    const onChangeStaff = (e) => {
        const val = e.value;
        setFormData({
            ...formData,
            doctorID: val,
            doctorID2: e,
        })
    }

    const onDescriptionChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const addAppointment = async (paymentData) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.post(`${serverLink}patient-portal/appointment/add`, formData, token).then(async (result) => {
            if (result.data.message === "success") {
                let item_id = result.data.appointment_id;
                const sendData = {...paymentData, itemID: item_id}
                await axios.post(`${serverLink}payment/post-payment`, sendData, token).then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Appointment Added Successfully");
                        setIsFormLoading(false);
                        props.setRebuildAppointment(generate_token(5));
                        document.getElementById("closePayment").click();
                        document.getElementById("closeAppointment").click();
                        resetFormData();
                        setScheduleSlots([])
                    } else {
                        setIsFormLoading(false);
                        toast.error("Something went wrong posting payment. Please try again!");
                    }
                })
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding appointment. Please try again!");
            }
        }) .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const handlePayment = () => {
        if (formData.consultationTypeID === ''){
            toast.error('Please select consultation type')
            return false
        }
        if (formData.serviceID === ''){
            toast.error('Please select consultation')
            return false
        }
        if (formData.appointmentDepartment === ''){
            toast.error('Please select specialisation')
            return false
        }
        if (formData.doctorID === ''){
            toast.error('Please select doctor')
            return false
        }
        if (formData.appointmentDate === ''){
            toast.error('Please select appointment date')
            return false
        }
        if (formData.slotID === ''){
            toast.error('Please select appointment slot')
            return false
        }
        document.getElementById('start-payment').click();
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Consultation Type</label>
                                <Select
                                    id="consultationTypeID"
                                    name="consultationTypeID"
                                    value={formData.consultationTypeID2}
                                    onChange={onChangeServiceType}
                                    options={serviceType}
                                    placeholder="Select Consultation Type"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Consultation</label>
                                <Select
                                    id="serviceID"
                                    name="serviceID"
                                    value={formData.serviceID2}
                                    onChange={onChangeService}
                                    options={services}
                                    placeholder="Select Consultation"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Specialisation</label>
                                <Select
                                    id="appointmentDepartment"
                                    name="appointmentDepartment"
                                    value={formData.appointmentDepartment2}
                                    onChange={onChangeSpecialisation}
                                    options={specialization}
                                    placeholder="Select Specialisation"
                                />
                            </div>


                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Doctor</label>
                                <Select
                                    id="doctorID"
                                    name="doctorID"
                                    value={formData.doctorID2}
                                    onChange={onChangeStaff}
                                    options={doctor}
                                    placeholder="Select Doctor"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Appointment Date</label>
                                <input
                                    type="date"
                                    name="appointmentDate"
                                    className="form-control"
                                    id="appointmentDate"
                                    value={formData.appointmentDate}
                                    onChange={onEdit}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                            {
                                IsGettingSchedules ?
                                    <div className="mb-3 form-group col-md-12">
                                        <div className="alert alert-info">Please wait while the system is fetching the doctor's schedule.</div>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                IsGettingSchedules ? <></> :
                                scheduleSlots.length > 0 ?
                                        <div className="mb-3 form-group col-md-12">
                                            <div className="row">
                                                {
                                                    scheduleSlots.map((item, index)=> {
                                                        let color = item.status === 'available' ? 'badge-info' : 'badge-danger';
                                                        return (
                                                            <div key={index}  className="form-check col-md-3 col-lg-3 col-6 col-sm-6 mb-3">
                                                                <input className="form-check-input" type="radio" disabled={item.status !== 'available'} data={item.slot} data_id={item.slotID} name="appointmentTime" onChange={onEdit} id={`flexCheckIndeterminate${index}`}/>
                                                                    <label className={`form-check-label badge-pill ${color} pl-2 pr-2`} for={`flexCheckIndeterminate${index}`}>
                                                                        {item.slot}
                                                                    </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div> : <></>
                            }

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Appointment Description</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="appointmentDetail"
                                    className="form-control"
                                    id="appointmentDetail"
                                    value={formData.appointmentDetail}
                                    onChange={onDescriptionChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={handlePayment}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <button data-toggle="modal" style={{display: 'none'}} id="start-payment" data-target={`#make-payment-modal`}>Open</button>
        <MakePayment
            id="make-payment-modal"
            title="Make Payment"
            close="closePayment"
            patientSerial={formData.patientSerial}
            patientName={formData.patientName}
            walletAmount={formData.walletAmount}
            paymentFor="Appointment"
            itemName={formData.itemName}
            itemAmount={formData.appointmentAmount}
            paymentType="Debit"
            IsFormLoading={IsFormLoading}
            onPostPayment={addAppointment}
            onError={()=>{console.log("Error")}}
        />
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(BookAppointment);