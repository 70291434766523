import PageTitle from "../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {formatDateAndTime, serverLink} from "../url";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import DataTable from "../common/data-table/data-table";
import Loader from "../common/loader";
import BookAppointment from "./book-appointment";

function PatientAppointmentList({loginData}) {
    const token = loginData[0]?.token;
    const patientSerial = loginData[0]?.patientSerial;
    const [patientDetails, setPatientDetails] = useState(loginData[0])
    const [IsLoading, setIsLoading] = useState(true)
    const [appointmentList, setAppointmentList] = useState([])
    const [rebuildAppointment, setRebuildAppointment] = useState(null);
    const header = ["S/N", "Department","Type", "Doctor",  "Date", "Time", "Status", "Details"];

    useEffect( () => {
        getData();
    }, [rebuildAppointment]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient-portal/appointment/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setAppointmentList(result.data);
                }else {
                    setAppointmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return appointmentList.length > 0 &&  appointmentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.departmentName}</td>
                        <td>{item.consultationType}</td>
                        <td>{item.doctorName}</td>
                        <td>{formatDateAndTime(item.appointmentDate, 'date')}</td>
                        <td>{item.appointmentTime}</td>
                        <td>{item.appointmentStatus}</td>
                        <td>{item.appointmentDetail}</td>
                        {/*<td>*/}
                        {/*    <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#appointments-modal"*/}
                        {/*       onClick={() => {*/}

                        {/*       }}>*/}
                        {/*        Edit*/}
                        {/*    </a></td>*/}
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Appointment"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">My Appointment(s)</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">
                                        <div className="btn-group">
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                data-toggle="modal"
                                                data-target="#appointment-modal"
                                            >
                                                <i className="fa fa-plus"></i> Add Appointment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <DataTable tableID="patient-appointments" header={header} body={showTable()}
                                               title="My Appointment(s) Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BookAppointment
                id="appointment-modal"
                title="Add Appointment"
                close="closeAppointment"
                patientSerial={patientDetails.patientSerial}
                patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                walletAmount={patientDetails.walletAmount}
                setRebuildAppointment={setRebuildAppointment}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientAppointmentList);
