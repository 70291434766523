import {calculateAge, currencyConverter, formatDateAndTime, imageExists, serverLink} from "../../url";
import React from "react";

export const PatientInfoCardLarge = ({patientDetails}) => {
    const imagePath = `${serverLink}public/uploads/patient_uploads/${patientDetails.passport}`;
    const placeholderImage = require('../../img/male_avater.jpg');
    return (
        <div className="col-md-12 table-bordered">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <center className="m-t-30">
                                <img
                                    src={imageExists(imagePath) ? imagePath : placeholderImage}
                                    alt="passport"
                                    className="rounded-circle border"
                                    width="150"
                                    height="150"
                                />
                                <h4 className="card-title m-t-10">{patientDetails.firstName} {patientDetails.middleName} {patientDetails.surname}</h4>
                                <h5 className="card-subtitle font-weight-bold text-dark">{patientDetails.patientSerial}</h5>
                                <h4 className="card-subtitle font-weight-bold text-danger">WALLET
                                    AMOUNT: {currencyConverter(patientDetails.walletAmount)}</h4>
                                <h5 className="card-subtitle"><span
                                    className="badge badge-success">{patientDetails.status}</span></h5>
                            </center>
                        </div>
                        <div className="col-md-4">
                            <small className="text-muted">Gender</small>
                            <h6>{patientDetails.gender ?? "N/A"}</h6>
                            <small className="text-muted">Date of Birth </small>
                            <h6>{formatDateAndTime(patientDetails.dateOfBirth, 'date')} -
                                ({calculateAge(patientDetails.dateOfBirth)} Years Old)</h6>
                            <small className="text-muted">Blood Group</small>
                            <h6>{patientDetails.bloodGroup ?? "N/A"}</h6>
                            <small className="text-muted">Marital Status</small>
                            <h6>{patientDetails.maritalStatus ?? "N/A"}</h6>
                            <small className="text-muted">Email address </small>
                            <h6>{patientDetails.emailAddress ?? "N/A"}</h6>
                            <small className="text-muted p-t-30 db">Phone</small>
                            <h6>{patientDetails.phoneNumber} | {patientDetails.altPhoneNumber ?? "N/A"}</h6>
                            <small className="text-muted p-t-30 db">Address</small>
                            <h6>{patientDetails.residentialAddress ?? "N/A"}</h6>
                        </div>
                        <div className="col-md-4">
                            <small className="text-muted">Nationality</small>
                            <h6>{patientDetails.nationality ?? "N/A"}</h6>
                            <small className="text-muted">State </small>
                            <h6>{patientDetails.state ?? "N/A"}</h6>
                            <small className="text-muted">LGA</small>
                            <h6>{patientDetails.lga ?? "N/A"}</h6>
                            <small className="text-muted">Ward</small>
                            <h6>{patientDetails.ward ?? "N/A"}</h6>
                            <small className="text-muted">Occupation </small>
                            <h6>{patientDetails.occupation ?? "N/A"}</h6>
                            <small className="text-muted p-t-30 db">Office Address</small>
                            <h6>{patientDetails.officeAddress ?? "N/A"}</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <hr style={{margin: '0px', padding: '0px'}}/>
                    <div className="card-body">
                        <h4><b>Next of Kin</b></h4>
                        <hr/>
                        <div className="row">
                            <div className="col-md-4">
                                <small className="text-muted p-t-30 db">Next of Kin</small>
                                <h6>{patientDetails.nextOfKinName ?? "N/A"}</h6>
                            </div>
                            <div className="col-md-4">
                                <small className="text-muted p-t-30 db">Next of Kin Phone Number</small>
                                <h6>{patientDetails.nextOfKinPhone ?? "N/A"}</h6>
                            </div>
                            <div className="col-md-4">
                                <small className="text-muted p-t-30 db">Relationship</small>
                                <h6>{patientDetails.nextOfKinRelationship ?? "N/A"}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}