import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import {Link} from "react-router-dom";
import {encryptData, generate_token, paymentAPIKey, serverLink} from "../url"
import logo from '../../images/logo-color.png'
import { useEffect } from "react";
import {usePaystackPayment} from "react-paystack";
import {showAlert} from "../common/sweetalert/sweetalert";

const Register = (props) => {
    const [loading, setLoading] = useState(false)
    const initValue = {
        firstName: "",
        middleName: "",
        surname: "",
        emailAddress: "",
        phoneNumber: "",
        password: "",
        amount: 10000,
        cpassword: "",
    }
    const [formData, setFormData] = useState(initValue)

    const config = {
        reference: "TRANX"+generate_token(10),
        email: formData.emailAddress,
        amount: formData.amount * 100,
        publicKey: paymentAPIKey,
        currency: 'NGN',
    };

    const onSuccess = (reference) => {
        handleRegistration(reference)
    };

    const onClose = () => {
        console.log('closed')
    }

    const initializePayment = usePaystackPayment(config);

    const paymentInitialize =  () => {
        if (formData.firstName.toString().trim() === ''){
            toast.error('Please enter first name');
            return false;
        }

        if (formData.surname.toString().trim() === ''){
            toast.error('Please enter surname');
            return false;
        }

        if (formData.phoneNumber.toString().trim() === ''){
            toast.error('Please enter phone number');
            return false;
        }

        if (formData.emailAddress.toString().trim() === ''){
            toast.error('Please enter email address');
            return false;
        }

        if (formData.password.toString().length < 8){
            toast.error('Password must be 8 or more characters');
            return false;
        }

        if (formData.password !== formData.cpassword) {
            toast.error('Passwords do not match. Please make sure your passwords match.');
            return false;
        }

        setLoading(true);
        toast.info("Please wait...")

        axios.post(`${serverLink}login/validate-patient`, formData).then((res) => {
            if (res.data.message === "success") {
                initializePayment(onSuccess, onClose)
            }else if(res.data.message === "exit"){
                setLoading(false);
                toast.success("Patient with the same email address or phone number already exist");
            } else {
                setLoading(false);
                toast.error('Something went wrong, please try again.')
            }
        }).catch((e)=>{
            setLoading(false);
            toast.error('Network Error!, please try again.')
        })
    }

    const handleRegistration = (ref) => {

        setLoading(true);
        toast.info("Please wait while the system is activation your payment...")

        const data = {
            ...formData,
            paymentReference: ref.trxref,
            paymentAmount: formData.amount,
            paymentStatus: "Paid",
            paymentFor: "Patient Registration",
            paymentType: "Debit",
            amountDue: formData.amount,
            amountPaid: formData.amount,
            itemName: "Patient Registration",
            paymentMethod: "Paystack",
            password: encryptData(formData.password)
        }
        axios.post(`${serverLink}login/patient/registration`, data).then((result) => {
            if (result.data.message === "success") {
                const patientSerial = result.data.patientSerial;
                const sendData = {
                    ...data,
                    patientSerial: patientSerial,
                    submittedByName: `${formData.firstName} ${formData.middleName} ${formData.surname}`,
                    submittedBy: patientSerial,
                }
                axios.post(`${serverLink}payment/website/post-payment`, sendData).then(async (res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        toast.success("Registration successful");
                        setTimeout(()=>{
                            window.location.href = "/login";
                        }, 3000)
                    } else {
                        setLoading(false);
                        toast.error("Something went wrong posting payment. Please try again!");
                    }
                }).catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });

            }else if(result.data.message === "exit"){
                setLoading(false);
                toast.success("Patient with the same email address or phone number already exist");
            } else {
                setLoading(false);
                toast.error('Something went wrong, please try again.')
            }
        }).catch((e)=>{
            setLoading(false);
            toast.error('Network Error!, please try again.')
        })
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    return (
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={{background: `url(../../assets/images/big/auth-bg.jpg) no-repeat center center`}}>
            <div className="auth-box">
                <div id="loginform">
                    <div className="d-flex justify-content-center align-items-center">
                        <span className="db"><img src={logo} width={163} height={45} alt="logo" /></span>

                    </div>
                    <h3 className="font-medium m-b-20 m-t-20 text-center">Create an Account</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="form-horizontal m-t-20" id="loginform" autoComplete="off" >
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="ti-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control form-control-lg"  value={formData.firstName} placeholder="First Name" id="firstName" onChange={onEdit} />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="ti-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control form-control-lg" autoComplete="off" value={formData.middleName} placeholder="Middle Name" id="middleName" onChange={onEdit}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="ti-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control form-control-lg" autoComplete="off" value={formData.surname} placeholder="Surname" id="surname" onChange={onEdit}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-phone"></i></span>
                                    </div>
                                    <input type="number" className="form-control form-control-lg" autoComplete="off" value={formData.phoneNumber} placeholder="Phone Number" id="phoneNumber" onChange={onEdit}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="ti-email"></i></span>
                                    </div>
                                    <input type="email" className="form-control form-control-lg" autoComplete="off" value={formData.emailAddress} placeholder="Email Address" id="emailAddress" onChange={onEdit}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon2"><i className="ti-lock"></i></span>
                                    </div>
                                    <input type="password" className="form-control form-control-lg" value={formData.password} id="password" onChange={onEdit}  placeholder="Password" />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-lock-open"></i></span>
                                    </div>
                                    <input type="password" className="form-control form-control-lg" value={formData.cpassword} id="cpassword" onChange={onEdit}  placeholder="Password" />
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">

                                    </div>
                                </div>
                                <div className="form-group text-center">
                                    {
                                        loading ?
                                            <div className="col-xs-12 p-b-20">
                                                <button type="button" className="btn btn-block btn-lg btn-info">
                                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                                </button>
                                            </div>
                                            :
                                            <div className="col-xs-12 p-b-20">
                                                <button className="btn btn-block btn-lg btn-info" type="button" id="pay" onClick={() => {
                                                    paymentInitialize();
                                                }}>Register</button>
                                            </div>
                                    }
                                </div>
                                <div className="col-md-12">
                                    <div className="  text-center">
                                        <Link to="/login" className="text-dark" style={{fontSize: '15px'}}> Already have an account? <b>Login here</b> </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Register);
